<template>
  <div class="grid grid-cols-1 px-7 pt-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">
        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">
              Контент
            </h1>
          </div>
          <div class="flex-shrink-0">
            <button
                @click="isDropdownOpen = !isDropdownOpen"
                id="dropdown-button-types"
                data-dropdown-toggle="dropdown-types"
                class="mr-3 p-2 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100 inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
            >
              {{ $t('document.add') }}
              <svg
                  class="w-2.5 h-2.5 ml-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6">
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"/>
              </svg>
            </button>
            <!-- Dropdown menu -->
            <div
                :class="isDropdownOpen ? 'show' : 'hidden'"
                id="dropdown-types"
                class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700">
              <ul
                  class="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdown-button-types">
                <li
                    style="cursor: pointer; color: #1c64f2"
                    class="p-2 w-full hover:bg-gray-100">
                  <router-link
                      :to="{name: 'ContentNewsCreate'}"
                      class="text-sm font-medium text-blue-700">
                    &plus; Новость
                  </router-link>
                </li>
                <li
                    v-if="isProfileCreate"
                    style="cursor: pointer; color: #1c64f2"
                    class="p-2 w-full hover:bg-gray-100">
                  <router-link
                      :to="{name: 'ContentProfileCreate'}"
                      class="text-sm font-medium text-blue-700">
                    &plus; Профиль
                  </router-link>
                </li>
                <li
                    style="cursor: pointer; color: #1c64f2"
                    class="p-2 w-full hover:bg-gray-100">
                  <router-link
                      :to="{name: 'ContentEventCreate'}"
                      class="text-sm font-medium text-blue-700">
                    &plus; Мероприятие
                  </router-link>
                </li>
                <!--                <li-->
                <!--                    style="cursor: pointer; color: #1c64f2"-->
                <!--                    class="p-2 w-full hover:bg-gray-100">-->
                <!--                  <router-link-->
                <!--                      :to="{name: 'ContentProductCreate'}"-->
                <!--                      class="text-sm font-medium text-blue-700">-->
                <!--                    &plus; Ярмарка-->
                <!--                  </router-link>-->
                <!--                </li>-->
                <li
                    style="cursor: pointer; color: #1c64f2"
                    class="p-2 w-full hover:bg-gray-100">
                  <router-link
                      :to="{name: 'ContentAudioGuildCreate'}"
                      class="text-sm font-medium text-blue-700">
                    &plus; Аудиогид
                  </router-link>
                </li>
              </ul>
            </div>
            <button v-if="checkRole(['admin', 'manager']) && tabName === contentEventTypeEnum.Profile" type="button"
                    @click="downloadProfile" :disabled="loadDataDownload" :class="{'opacity-50': loadDataDownload}"
                    class="mr-3 p-2 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100">
              {{ $t('users.download') }}
            </button>
            <button v-if="checkRole(['admin', 'manager']) && tabName === contentEventTypeEnum.Event" type="button" @click="download" :disabled="loadDataDownload" :class="{'opacity-50': loadDataDownload}" class="mr-3 p-2 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100">{{ $t('users.download') }}</button>
          </div>
        </div>

        <div class="flex w-full justify-between">
          <div class="flex-col p-7 w-full">
            <button
                :disabled="isLoadingData"
                @click="tabName = contentEventTypeEnum.News"
                :class="tabName === contentEventTypeEnum.News ? 'bg-blue-500' : 'bg-gray-400'"
                class="px-7 mr-5 mt-5 py-3 rounded-md text-white font-medium hover:bg-blue-500">
              <span>Новости</span>
            </button>
            <button
                :disabled="isLoadingData"
                @click="tabName = contentEventTypeEnum.Profile"
                :class="tabName === contentEventTypeEnum.Profile ? 'bg-blue-500' : 'bg-gray-400'"
                class="px-7 mr-5 mt-5 py-3 rounded-md text-white font-medium hover:bg-blue-500">
              <span>Профиль</span>
            </button>
            <!--            <button-->
            <!--                :disabled="isLoadingData"-->
            <!--                @click="tabName = contentEventTypeEnum.Product"-->
            <!--                :class="tabName === contentEventTypeEnum.Product ? 'bg-blue-500' : 'bg-gray-400'"-->
            <!--                class="px-7 mr-5 mt-5 py-3 rounded-md text-white font-medium hover:bg-blue-500">-->
            <!--              <span>Ярмарка</span>-->
            <!--            </button>-->
            <button
                :disabled="isLoadingData"
                @click="tabName = contentEventTypeEnum.Event"
                :class="tabName === contentEventTypeEnum.Event ? 'bg-blue-500' : 'bg-gray-400'"
                class="px-7 mr-5 mt-5 py-3 rounded-md text-white font-medium hover:bg-blue-500">
              <span>Мероприятия</span>
            </button>
            <button
                :disabled="isLoadingData"
                @click="tabName = contentEventTypeEnum.AudioGuild"
                :class="tabName === contentEventTypeEnum.AudioGuild ? 'bg-blue-500' : 'bg-gray-400'"
                class="px-7 mr-5 mt-5 py-3 rounded-md text-white font-medium hover:bg-blue-500">
              <span>Аудиогиды</span>
            </button>
          </div>
        </div>

        <div
            v-if="!checkRole(['exhibitor', 'content_exhibitor'])"
            class="flex w-full justify-between">
          <div class="flex-col p-7 w-full">
            <div
                class="flex flex-col w-full">
              <div class="w-full mt-10">
                <input-text v-model="search" label="Поиск"/>
              </div>

              <hr class="mt-8 mb-5"/>

              <div class="flex w-full mb-4 mt-5">
                <div class="flex-col w-1/2">
                  <Multiselect
                      @change="onHandleChangeSelectedExhibitor"
                      v-model="selectedExhibitors"
                      :options="exhibitors"
                      max="1"
                      :mode="'tags'"
                      :valueProp="'id'"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :placeholder="$t('document.byExhibitor')"/>
                </div>
                <div class="flex-col w-1/2">
                  <Multiselect
                      v-model="selectedStatusCards"
                      :options="statusCards"
                      max="1"
                      :mode="'tags'"
                      :valueProp="'id'"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :searchable="true"
                      :placeholder="$t('document.byStatus')"/>
                </div>
                <div class="flex-col w-1/2">
                  <Multiselect
                      v-model="selectedParticipants"
                      :options="participants"
                      max="1"
                      :mode="'tags'"
                      :valueProp="'id'"
                      track-by="nameRu"
                      label="nameRu"
                      :close-on-select="false"
                      :searchable="true"
                      placeholder="По участнику"/>
                </div>
              </div>
              <div class="flex w-full">
                <div class="flex-col w-1/3 mr-4">
                  <VueDatePicker
                      v-model="createdAt"
                      :placeholder="$t('document.byCreatedAt')"
                      :partial-range="false"
                      locale="ru"
                      model-type="yyyy-MM-dd"
                      :format="'dd.MM.yyyy'"
                      auto-apply
                      :enable-time-picker="false"/>
                </div>
                <div class="flex-col w-1/3 mr-4">
                  <VueDatePicker
                      v-model="updatedAt"
                      :placeholder="$t('document.byUpdatedAt')"
                      :partial-range="false"
                      locale="ru"
                      model-type="yyyy-MM-dd"
                      :format="'dd.MM.yyyy'"
                      auto-apply
                      :enable-time-picker="false"/>
                </div>
                <div class="flex-col w-1/3 mr-4" v-if="tabName === contentEventTypeEnum.Event">
                  <input-checkbox
                      v-model="isScheduleCloseNowDate"
                      label="Ближайшие"
                      id="closest-date"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            v-else
            class="flex w-full justify-between">
          <div class="flex-col p-7 w-full">
            <div
                class="flex flex-col w-full">
              <div class="w-full mt-10">
                <input-text v-model="search" label="Поиск"/>
              </div>
            </div>
          </div>
        </div>

        <div
            v-if="tabName === 'news' && !isCreateNews"
            class="alert"
            style="margin-bottom: 30px; background: #fffcec;">
          <div style="padding: 15px 25px; border: 1px solid #dea366; margin: 0 30px; border-radius: 15px;" class="alert-content">
            {{newsQuotaError}}
          </div>
        </div>

        <div class="flex w-full justify-between items-center border-t">
          <div class="flex-col p-7 w-1/2">
            <template v-if="deleteIds.length">
              <div>
                <button
                    :disabled="isLoadingDelete"
                    @click.prevent="onHandleClickItemsDelete" v-if="deleteIds.length"
                    class="flex px-6 py-4 bg-primary-500 rounded-md text-white hover:bg-primary-700">
                  <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                  </svg>
                  <span>{{ $t('common.delete') }}</span>
                </button>
              </div>
            </template>
            <template v-else>
              <div>
                <span class="text-gray-500">{{ $t('common.found') }}</span> {{ total }}
              </div>
            </template>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="isLoadingData"/>
                <table v-if="!isLoadingData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                  <tr>
                    <th
                        v-if="!checkRole(['exhibitor', 'content_exhibitor'])"
                        scope="col"
                        class="p-4">
                      <div class="flex items-center">
                        <input v-model="isAllCheckboxDelete"
                               @click="onHandleSelectedAllDelete"
                               id="checkbox-all" type="checkbox"
                               class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      <div class="flex items-center cursor-pointer"
                           :class="{'text-black font-normal': sort === 'exhibitor_name'}"
                           @click.prevent="sortBy('exhibitor_name')">
                        <span>{{ $t('document.exhibitorName') }}</span>
                        <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1">
                          <path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/>
                        </svg>
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      <div class="flex items-center cursor-pointer" :class="{'text-black': sort === 'name'}"
                           @click.prevent="sortBy('name')">
                        <span>{{ $t('document.documentName') }}</span>
                        <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1">
                          <path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/>
                        </svg>
                      </div>
                    </th>
                    <th v-if="tabName === contentEventTypeEnum.Event" scope="col"
                        class="p-4 text-left font-light text-gray-400">
                      <div class="flex items-center cursor-pointer"
                           :class="{'text-black font-normal': sort === 'scheduleFirst'}"
                           @click.prevent="sortBy('scheduleFirst')">
                        <span>{{ $t('document.scheduleFirst') }}</span>
                        <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1">
                          <path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/>
                        </svg>
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      <div class="flex items-center cursor-pointer"
                           :class="{'text-black font-normal': sort === 'created_at'}"
                           @click.prevent="sortBy('created_at')">
                        <span>{{ $t('document.created') }}</span>
                        <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1">
                          <path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/>
                        </svg>
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      <div class="flex items-center cursor-pointer"
                           :class="{'text-black font-normal': sort === 'updated_at'}"
                           @click.prevent="sortBy('updated_at')">
                        <span>{{ $t('document.updated') }}</span>
                        <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1">
                          <path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/>
                        </svg>
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      <div class="flex items-center cursor-pointer"
                           :class="{'text-black font-normal': sort === 'status'}" @click.prevent="sortBy('status')">
                        <span>{{ $t('document.status') }}</span>
                        <svg :class="{'rotate-180': sortDirection === 'asc'}" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 12 7" class="w-3 h-3 ml-1 mt-1">
                          <path fill="currentColor" d="M0 6h6.86v1H0zm0-3h9.43v1H0zm0-3h12v1H0z"/>
                        </svg>
                      </div>
                    </th>
                    <th scope="col" class=""></th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                  <router-link to="" tag=""></router-link>
                  <tr
                      v-for="item of items"
                      :key="item.id"
                      :class="{'bg-neutral-100 opacity-50': item?.isDeleted, 'bg-blue-50': item?.isNotRead}"
                      class="hover:bg-gray-100"
                      @dblclick="$router.push(trItemUrl(item.id))"
                  >
                    <td class="w-4" v-if="checkRole(['admin', 'content_manager', 'manager'])">
                      <div class="p-4 flex items-center">
                        <input
                            v-model="deleteIds"
                            :value="item.id"
                            aria-describedby="checkbox-1"
                            type="checkbox"
                            class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                      </div>
                    </td>
                    <td class="px-4">
                      <router-link class="py-4 flex text-base font-medium text-gray-900 whitespace-pre-wrap w-full"
                                   :to="trItemUrl(item.id)">
                        {{ item.user?.name }}
                      </router-link>
                    </td>
                    <td>
                      <router-link class="p-4 flex flex-col items-start" :to="trItemUrl(item.id)"
                                   style="overflow-wrap: anywhere;">
                        <p class="text-base font-medium text-gray-900">
                          <span>{{ item.nameRu }}</span>
                        </p>
                        <p class="text-sm text-gray-500">{{ item.nameEn }}</p>
                        <p>
                          <small style="color: #ef9a9a">
                            {{ item?.errorMessage }}
                          </small>
                        </p>
                      </router-link>
                    </td>
                    <td v-if="tabName === contentEventTypeEnum.Event">
                      <router-link class="p-4 text-base font-medium text-gray-900" :to="trItemUrl(item.id)">
                        {{ item.nearSession ? moment(item.nearSession).format('DD.MM.YYYY') : '-' }}
                      </router-link>
                    </td>
                    <td>
                      <router-link class="p-4 text-base font-medium text-gray-900" :to="trItemUrl(item.id)">
                        {{ moment(item.createdAt).format('DD.MM.YYYY') }}
                      </router-link>
                    </td>
                    <td class="p-4">
                      <router-link :to="trItemUrl(item.id)">
                        <p class="text-base font-medium text-gray-900">
                          {{ moment(item.updatedAt).format('DD.MM.YYYY') }}
                        </p>
                      </router-link>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">
                        <span class="px-4 py-1 rounded-xl whitespace-nowrap"
                              :class="['bg-status-' + item.status]">
                          {{ item.statusProcess?.name }}
                        </span>
                      </p>
                    </td>
                    <td class="text-right px-3">
                      <router-link :to="trItemUrl(item.id)" class="py-2  text-sm font-medium text-center">
                        <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path fill-rule="evenodd"
                                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                clip-rule="evenodd"></path>
                        </svg>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!isLoadingData && !items.length" class="text-xl text-center p-7">{{
                    $t('common.notFound')
                  }}</h3>
                <div class="flex-col p-7 w-2/2 text-right">
                  <nav v-if="contentPagination && contentPagination.lastPage > 1">
                    <ul class="inline-flex -space-x-px">
                      <li v-for="(link, index) of contentPagination.links">
                        <a v-if="link.url" href="#"
                           @click.prevent="entityPaginate(link.label)"
                           :class="classInputPagination(index, link.active)"
                           class="px-3 py-2 border border-gray-300">
                          <span v-html="link.label"/>
                        </a>
                        <a v-else
                           :class="classInputPagination(index, link.active)"
                           class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                          <span v-html="link.label"/>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Spinner from "../../flowbite/spinner.vue";
import InputText from "../../flowbite/form/inputText.vue";
import moment from 'moment'
import '@vuepic/vue-datepicker/dist/main.css'
import {onMounted, ref, watch} from "vue";
import {contentEventTypeEnum} from '../../../constants/contentTypeEnum';
import VueDatePicker from '@vuepic/vue-datepicker';
import Multiselect from '@vueform/multiselect';
import {
  serviceContentNewsList,
  serviceContentNewsMultiDelete
} from "../../../service/contents/news/serviceContentNews.js";
import {useRoute, useRouter} from 'vue-router'
import {
  serviceContentCheckCreate,
  serviceContentProfileList, serviceContentProfileMultiDelete
} from "../../../service/contents/news/serviceContentProfile.js";
import {
  serviceContentEventList,
  serviceContentEventMultiDelete
} from "../../../service/contents/news/serviceContentEvent.js";
import {
  serviceContentProductList,
  serviceContentProductMultiDelete
} from "../../../service/contents/news/serviceContentProduct.js";
import {
  serviceContentAudioGuildList,
  serviceContentAudioGuildMultiDelete
} from "../../../service/contents/news/serviceContentAudioGuild.js";
import {checkRole} from "../../../utilits/functions.js";
import {serviceStatusesList} from "../../../service/lists/serviceListStatuses.js";
import {serviceExhibitorList} from "../../../service/lists/serviceListContentExhibitors.js";
import {serviceNewsQuota, serviceUserParticipant} from "../../../service/contents/serviceContent.js";
import axios from "axios";
import InputCheckbox from "../../flowbite/form/inputCheckbox.vue";
import {serviceParticipantList} from "../../../service/lists/serviceListParticipantsFilter.js";

const router = useRouter();
const route = useRoute();

const isProfileCreate = ref(false);

let debounceTimeout = null;
const isAllCheckboxDelete = ref(false);
const isLoadingData = ref(true);
const tabName = ref(contentEventTypeEnum.News);
const isDropdownOpen = ref(false);
const deleteIds = ref([]);
const search = ref('');
const items = ref([]);
const total = ref(0);
const isLoadingDelete = ref(false);
const selectedStatusCards = ref([]);
const statusCards = ref([]);
const selectedExhibitors = ref([]);
const exhibitors = ref([]);
const createdAt = ref(null);
const updatedAt = ref(null);
const isScheduleCloseNowDate = ref(false);
const participants = ref([]);
const selectedParticipants = ref([]);
const loadDataDownload = ref(false);
const isCreateNews = ref(false);
const newsQuotaError = ref(null);

const limit = ref(30);
const page = ref(1);
const contentPagination = ref(null);

serviceNewsQuota().then(response => {
  isCreateNews.value = response.data;

  if (!isCreateNews.value)
    newsQuotaError.value = 'Вы больше не можете создавать новости (лимит по квотам)';
});

serviceStatusesList().then(response => {
  if (response.success) {
    statusCards.value = response.data || [];
  }
});

const onLoadingExhibitor = () => {
  serviceExhibitorList(tabName.value).then(response => {
    if (!response.success)
      return null;

    exhibitors.value = response.data || [];
  });
}

onMounted(async () =>{
  // return whenever you need to save filers and tabName values in url №1

  // let queryParams = route.query
  let params = {}
  // if(Object.keys(queryParams).length){
  //   params = queryParams
  //   selectedStatusCards.value[0] = queryParams?.status
  //   selectedExhibitors.value[0] = queryParams?.exhibitor
  //   selectedParticipants.value[0] = queryParams.participant
  //   createdAt.value = queryParams.createdAt
  //   updatedAt.value = queryParams.updatedAt
  // }
  // if(queryParams?.tabName){
  //   tabName.value = route.query?.tabName
  // }

  await requestContent(params);
  await onLoadingExhibitor();
})

const onLoadingParticipantList = (contentType) => {
  serviceParticipantList({contentType}).then(response => {
    participants.value = response.data || [];
  })
}

onLoadingParticipantList(tabName.value);

const funcFilterResponseDelete = (notDeletedIds) => {
  const missingIds = deleteIds.value.filter(id => !notDeletedIds.includes(id));
  const filteredItems = items.value.filter(item => !missingIds.some(id => id === item.id));

  filteredItems.map(item => {
    if (notDeletedIds.includes(item.id)) {
      item.errorMessage = 'Внимание! Данная запись не была удалена';
    } else {
      item.errorMessage = null;
    }

    return item;
  });

  setTimeout(() => {
    items.value.map(item => {
      item.errorMessage = null;
    })
  }, 8000);

  items.value = filteredItems;

  total.value = total.value - (deleteIds.value.length || 0);
  if (total.value < 0)
    total.value = 0;

  deleteIds.value = [];
}

const onHandleClickItemsDelete = () => {
  if (checkRole(['exhibitor', 'content_exhibitor']))
    return null;

  if (contentEventTypeEnum.News === tabName.value) {
    if (!deleteIds.value?.length)
      return null;

    isLoadingDelete.value = true;
    serviceContentNewsMultiDelete(deleteIds.value).then(response => {
      isLoadingDelete.value = false;

      if (!response.success)
        return null;

      funcFilterResponseDelete(response.data);
    });
  } else if (contentEventTypeEnum.Profile === tabName.value) {
    if (!deleteIds.value?.length)
      return null;

    isLoadingDelete.value = true;
    serviceContentProfileMultiDelete(deleteIds.value).then(response => {
      isLoadingDelete.value = false;

      if (!response.success)
        return null;

      funcFilterResponseDelete(response.data);
    });
  } else if (contentEventTypeEnum.Product === tabName.value) {
    if (!deleteIds.value?.length)
      return null;

    isLoadingDelete.value = true;
    serviceContentProductMultiDelete(deleteIds.value).then(response => {
      isLoadingDelete.value = false;

      if (!response.success)
        return null;

      funcFilterResponseDelete(response.data);
    });
  } else if (contentEventTypeEnum.Product === tabName.value) {
    if (!deleteIds.value?.length)
      return null;

    isLoadingDelete.value = true;
    serviceContentProductMultiDelete(deleteIds.value).then(response => {
      isLoadingDelete.value = false;

      if (!response.success)
        return null;

      funcFilterResponseDelete(response.data);
    });
  } else if (contentEventTypeEnum.Event === tabName.value) {
    if (!deleteIds.value?.length)
      return null;

    isLoadingDelete.value = true;
    serviceContentEventMultiDelete(deleteIds.value).then(response => {
      isLoadingDelete.value = false;

      if (!response.success)
        return null;

      funcFilterResponseDelete(response.data);
    });
  } else if (contentEventTypeEnum.AudioGuild === tabName.value) {
    if (!deleteIds.value?.length)
      return null;

    isLoadingDelete.value = true;
    serviceContentAudioGuildMultiDelete(deleteIds.value).then(response => {
      isLoadingDelete.value = false;

      if (!response.success)
        return null;

      funcFilterResponseDelete(response.data);
    });
  }
}

const onHandleSelectedAllDelete = () => {
  deleteIds.value = !isAllCheckboxDelete.value ? items.value.map(item => item.id) : [];
}

if (checkRole(['content_exhibitor'])) {
  serviceContentCheckCreate().then(response => {
    if (response.success) {
      isProfileCreate.value = response.data;
    }
  });
} else isProfileCreate.value = true;

const trItemUrl = (id) => {
  let data;

  if (contentEventTypeEnum.News === tabName.value) {
    data = {name: 'ContentNewsEdit', params: {id}};
  } else if (contentEventTypeEnum.Event === tabName.value) {
    data = {name: 'ContentEventEdit', params: {id}};
  } else if (contentEventTypeEnum.Product === tabName.value) {
    data = {name: 'ContentProductEdit', params: {id}};
  } else if (contentEventTypeEnum.Profile === tabName.value) {
    data = {name: 'ContentProfileEdit', params: {id}};
  } else if (contentEventTypeEnum.AudioGuild === tabName.value) {
    data = {name: 'ContentAudioGuildEdit', params: {id}};
  }

  if (data) {
    return data
  }
}

const entityPaginate = (currentPage) => {
  const pagination = contentPagination.value;

  if (typeof currentPage === 'string' && currentPage?.indexOf('Next') !== -1)
    currentPage = pagination?.lastPage ? (pagination.currentPage < pagination?.lastPage ? pagination.currentPage + 1 : pagination.currentPage) : pagination.currentPage;
  if (typeof currentPage === 'string' && currentPage?.indexOf('Previous') !== -1)
    currentPage = pagination.currentPage <= 1 ? 1 : pagination.currentPage - 1;

  const params = {
    status: selectedStatusCards.value?.length ? selectedStatusCards.value[0] : null,
    exhibitor: selectedExhibitors.value?.length ? selectedExhibitors.value[0] : null,
    participant: selectedParticipants.value?.length ? selectedParticipants.value[0] : null,
    createdAt: createdAt.value || null,
    updatedAt: updatedAt.value || null,
    page: currentPage,
  };

  requestContent(params);
}

const requestContent = (params) => {
  if (tabName.value === contentEventTypeEnum.News) {
    isLoadingData.value = true;
    serviceContentNewsList(params).then(response => {
      isLoadingData.value = false;
      items.value = response.data || [];
      total.value = response.pagination?.total || 0;
      contentPagination.value = response.pagination || null;
    });
  } else if (tabName.value === contentEventTypeEnum.Profile) {
    isLoadingData.value = true;
    serviceContentProfileList(params).then(response => {
      isLoadingData.value = false;
      items.value = response.data || [];
      total.value = response.pagination?.total || 0;
      contentPagination.value = response.pagination || null;
    });
  } else if (tabName.value === contentEventTypeEnum.Event) {

    params.isScheduleCloseNowDate = isScheduleCloseNowDate.value;

    isLoadingData.value = true;
    serviceContentEventList(params).then(response => {
      isLoadingData.value = false;
      items.value = response.data || [];
      total.value = response.pagination?.total || 0;
      contentPagination.value = response.pagination || null;
    });
  } else if (tabName.value === contentEventTypeEnum.Product) {
    isLoadingData.value = true;
    serviceContentProductList(params).then(response => {
      isLoadingData.value = false;
      items.value = response.data || [];
      total.value = response.pagination?.total || 0;
      contentPagination.value = response.pagination || null;
    });
  } else if (tabName.value === contentEventTypeEnum.AudioGuild) {
    isLoadingData.value = true;
    serviceContentAudioGuildList(params).then(response => {
      isLoadingData.value = false;
      items.value = response.data || [];
      total.value = response.pagination?.total || 0;
      contentPagination.value = response.pagination || null;
    });
  }
}

watch(tabName, () => {
  // return whenever you need to save filers and tabName values in url №2
  // router.push({query: { ...route.query, tabName: tabName.value}})
  onLoadingExhibitor();

  const params = {
    status: selectedStatusCards.value?.length ? selectedStatusCards.value[0] : null,
    exhibitor: selectedExhibitors.value?.length ? selectedExhibitors.value[0] : null,
    createdAt: createdAt.value || null,
    updatedAt: updatedAt.value || null,
    page: page.value || 1,
  };

  requestContent(params);
});

watch([selectedParticipants, selectedStatusCards, createdAt, updatedAt, isScheduleCloseNowDate, selectedExhibitors], () => {
  const params = {
    status: selectedStatusCards.value?.length ? selectedStatusCards.value[0] : null,
    exhibitor: selectedExhibitors.value?.length ? selectedExhibitors.value[0] : null,
    participant: selectedParticipants.value?.length ? selectedParticipants.value[0] : null,
    createdAt: createdAt.value || null,
    updatedAt: updatedAt.value || null,
  };

  // return whenever you need to save filers and tabName values in url №3
  // router.push({query: {...route.query, ...params}})
  requestContent(params);
});

// Функция, которая будет вызвана при изменении значения search
const handleSearchChange = (newValue) => {
  // Очищаем предыдущий таймаут, если он уже был установлен
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }

  // Устанавливаем новый таймаут для отправки запроса через 500 миллисекунд
  debounceTimeout = setTimeout(() => {
    requestContent({search: search.value});
  }, 500);
};

watch(search, handleSearchChange);

const getSearchDownloadParams = () => {

  return {
    search: search.value,
    status: selectedStatusCards.value?.length ? selectedStatusCards.value[0] : null,
    exhibitor: selectedExhibitors.value?.length ? selectedExhibitors.value[0] : null,
    createdAt: createdAt.value || null,
    updatedAt: updatedAt.value || null,
  }
}

const downloadProfile = () => {
  loadDataDownload.value = true

  let config = {
    responseType: 'blob',
    params: getSearchDownloadParams(),
  }

  axios.get('/v1/contents/profiles/download', config)
      .then(response => {
        const blob = new Blob([response.data], {type: response.headers['content-type']})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'profiles.xlsx'
        link.click()
        URL.revokeObjectURL(link.href)

        loadDataDownload.value = false
      }).catch(console.error)
}

const getSearchParams = () => {

  return {
    search: search.value,
    status: selectedStatusCards.value?.length ? selectedStatusCards.value[0] : null,
    exhibitor: selectedExhibitors.value?.length ? selectedExhibitors.value[0] : null,
    createdAt: createdAt.value || null,
    updatedAt: updatedAt.value || null,
  }
}

const download = () => {
  loadDataDownload.value = true

  let config = {
    responseType: 'blob',
    params: getSearchParams(),
  }

  axios.get('/v1/contents/events/download', config)
      .then(response => {
        const blob = new Blob([response.data], {type: response.headers['content-type']})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'events.xlsx'
        link.click()
        URL.revokeObjectURL(link.href)

        loadDataDownload.value = false
      }).catch(console.error)
}

// Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === contentPagination?.links?.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});

</script>
